import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_button, {
      type: "primary",
      loading: $setup.loading,
      disabled: $setup.isEmpty($setup.session.id),
      onClick: $setup.buy
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.buttonText), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled", "onClick"])
  ]))
}