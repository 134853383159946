
import { Button as AButton } from 'ant-design-vue';
import isEmpty from 'lodash-es/isEmpty';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useFormStore } from '@/store/modules/form';

export default {
  components: {
    AButton
  },
  props: {
    sessionId: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: 'DETAYLI RAPORU SATIN AL'
    }
  },
  setup(props: any) {
    const loading = ref(false);
    const store = useFormStore();

    const { session, errors, session_exchange_token } = storeToRefs(store);
    if (!isEmpty(props.sessionId)) {
      store.getSession(props.sessionId);
    }
    const buy = async() => {
      loading.value = true;
      // await store
      await store.sessionTokenExchange();
      if (isEmpty(errors.value.find(e => e.method === 'sessionTokenExchange')) && !isEmpty(session_exchange_token.value)) {
        store.createPaymentRequest();
      } else {
        loading.value = false;
      }
    };

    return {
      loading,
      session,
      buy,
      isEmpty
    };
  }
};
