
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import { ApexOptions } from 'apexcharts';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';

import BuyDetailedReport from './components/BuyDetailedReport.vue';

import { useAdminStore } from '@/store/modules/admin';
import { useFormStore } from '@/store/modules/form';

import TheForm from '@/components/TheForm.vue';
import TheInput from '@/components/TheInput.vue';

export default defineComponent({
  components: { BuyDetailedReport, TheForm, TheInput, CheckCircleOutlined },
  setup() {
    const store = useFormStore();
    const admin_store = useAdminStore();
    const router = useRouter();
    const { basic_result, session } = storeToRefs(store);

    const session_id = router.currentRoute.value.query.sid?.toString() || store.session.id;

    const result_loading = ref(true);
    if (!session_id) {
      router.push({ path: '/404' });
      // return false;
    }
    const chart_options: Ref<ApexOptions> = ref({});

    onMounted(async() => {
      await store.getSession(session_id);
      await store.fetchBasicResult();
      chart_options.value = {
        chart: {
          height: '500px',
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: ['İnovasyon Stratejisi', 'İnovasyon Yönetimi', 'İnovasyon Süreci', 'İnovasyon Altyapısı', 'Açık İnovasyon'],
          labels: {
            show: true,
            style: {
              colors: ['white', 'white', 'white', 'white', 'white']
            }
          }
        },
        legend: {
          position: 'top'
        },
        series: [
          {
            name: 'Score',
            data: [
              basic_result.value.innovationStrategy_Score,
              basic_result.value.innovationManagment_Score,
              basic_result.value.innovationProcess_Score,
              basic_result.value.innovationInfrastructure_Score,
              basic_result.value.openInnovation_Score
            ],
            color: 'red'
          }
        ],
        theme: {
          mode: 'light',
          monochrome: {
            color: 'white'
          }
        }
      } as ApexOptions;
      result_loading.value = false;
    });

    const free_code = ref('');
    const free_code_checking = ref(false);
    const free_code_accepted = ref(false);
    const useFreePass = async() => {
      free_code_checking.value = true;
      await admin_store.useFreePass(free_code.value, session_id);
      if (!admin_store.errors.some(error => error.method === 'useFreePass')) {
        free_code_accepted.value = true;
      }
      free_code_checking.value = false;
    };

    return {
      Yup,
      result_loading,
      session,
      basic_result,
      chart_options,
      // Free pass
      free_code,
      free_code_checking,
      free_code_accepted,
      useFreePass
    };
  }
});
